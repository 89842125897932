@tailwind base;
@tailwind components;
@tailwind utilities;
@import url("https://use.typekit.net/gus5bsd.css");

* {
  font-family: sofia-pro, sans-serif;
}

html,
body {
  background: #F2F1F0;
  color: #1B1557;
  overflow: hidden;
}

@layer components {
  .card {
    @apply flex flex-col items-center text-center space-y-1.5 bg-white shadow-md px-4 py-12 h-full;
  }

  .big-card {
    @apply flex flex-col items-center text-center space-y-1.5 bg-white shadow-md px-4 py-8 sm:pt-12 sm:pb-28 h-full;
  }


  .top-row {
    @apply relative overflow-hidden w-full flex justify-center items-center py-4 bg-kb-blue font-semibold uppercase cursor-pointer text-kb-blue;
  }

  .even-label-row {
    @apply bg-lightgrey w-2/5 flex items-center px-3 font-light;
  }

  .label-row {
    @apply bg-white w-2/5 flex items-center px-3 font-light;
  }

  .row {
    @apply bg-white text-sm w-1/5 flex flex-col justify-center items-center text-center px-3 py-4 font-light;
  }

  .even-row {
    @apply bg-lightgrey text-sm w-1/5 flex flex-col justify-center items-center text-center px-3 py-4 font-light;
  }
}

@screen sm {
  .top-row {
    @apply text-xl mx-3 uppercase bg-kb-blue text-white font-semibold shadow-md cursor-default
  }

  .even-label-row {
    @apply text-lg mx-3 pl-10 shadow-md;
  }

  .label-row {
    @apply text-lg mx-3 pl-10 shadow-md;
  }

  .row {
    @apply text-lg mx-3 shadow-md;
  }

  .even-row {
    @apply text-lg  mx-3 shadow-md;
  }

  .two-col-top-row {
    @apply text-xl mx-3 uppercase bg-kb-blue text-white font-semibold shadow-md cursor-default
  }

  .two-col-even-label-row {
    @apply text-lg w-full mx-3 pl-10 shadow-md;
  }

  .two-col-label-row {
    @apply text-lg w-full mx-3 pl-10 shadow-md;
  }

  .two-col-row {
    @apply text-lg w-full mx-3 shadow-md;
  }

  .two-col-even-row {
    @apply text-lg w-full mx-3 shadow-md;
  }
}
